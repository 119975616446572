import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { getPageInfo } from 'utils';
import { getCategoryTitleFromURL } from 'utils/getCategoryPath';
import { BLOG_KEYWORDS } from 'globals';

import { BlogList, CategoryMenuBlog } from 'components/Blog';
import { BeInTouch } from 'components/Paragraphs/Types';
import { SEO, Layout, Pagination, SearchBar } from 'components';

const BlogCategoryTemplate = ({
  location,
  pageContext: { currentPage, numPagesCategory, categoryName },
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    allNodeBlog: { edges: posts },
    allTaxonomyTermBlogCategory: { edges: category },
    nodePage,
  },
}) => {
  const {
    paginationPath,
    isFirst,
    isLast,
    prevPage,
    nextPage,
    metaTitle,
    metaDescription,
  } = getPageInfo(location.pathname, currentPage, numPagesCategory, categoryName);

  return (
    <Layout
      location={location}
      title={siteTitle}
      isDefaultBreadcrumbs={nodePage?.field_automatic_breadcrumbs}
      customBreadcrumbs={nodePage?.relationships?.field_breadcrumbs}
      type="blog"
      nodeTitle={categoryName}
      parentBreadcrumb="/blog/"
      parentTitle="Blog"
    >
      <SEO
        title={metaTitle}
        keywords={BLOG_KEYWORDS}
        currentUrl={location.pathname}
        description={metaDescription}
        robots="noindex, nofollow"
      />
      <h1 className="page-hidden-title">{getCategoryTitleFromURL(category)}</h1>
      <div className="blog-top-wrapper page-top">
        <CategoryMenuBlog categories={category} />
        <SearchBar />
      </div>
      <BlogList posts={posts} />
      <Pagination
        isFirst={isFirst}
        numPages={numPagesCategory}
        currentPage={currentPage}
        isLast={isLast}
        nextPage={nextPage}
        prevPage={prevPage}
        paginationPath={paginationPath}
      />
      <BeInTouch title="Want To Work With Us?" buttonTitle="Let’s Talk" blueBg="blue-bg" />
    </Layout>
  );
};

BlogCategoryTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPagesCategory: PropTypes.number,
    categoryName: PropTypes.string,
  }),
  data: PropTypes.shape({
    webformWebform: PropTypes.object,
    allNodeBlog: PropTypes.object,
    allTaxonomyTermBlogCategory: PropTypes.object,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    nodePage: PropTypes.object,
  }),
};

export default BlogCategoryTemplate;

export const query = graphql`
  query blogCategoryPageQuery($categoryId: Int!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allNodeBlog(
      sort: { fields: [created], order: DESC }
      filter: {
        relationships: {
          field_blog_category: { elemMatch: { drupal_internal__tid: { eq: $categoryId } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      ...AllBlog
      nodes {
        field_automatic_breadcrumbs
        relationships {
          field_breadcrumbs {
            id
            field_link {
              title
              uri
            }
          }
        }
      }
    }
    allTaxonomyTermBlogCategory {
      ...AllTaxonomyTermBlogCategory
    }
  }
`;
